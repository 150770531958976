const PATH_NAMES = {
    BOOKING: "/booking/:bookingId",
    BOOKINGS: "/bookings",
    NEW_BOOKING: "/new-bookings",
    GUEST: "/guest/:guestId",
    GUESTS: "/guests",
    NEW_GUEST: "/new-guests",
    ROOM: "/room/:roomId",
    ROOMS: "/rooms",
    NEW_ROOM: "/new-room",
    ROOM_TYPE: "/roomType/:roomTypeId",
    ROOM_TYPES: "/roomTypes",
    NEW_ROOM_TYPE: "/new-roomType"
}

export default PATH_NAMES