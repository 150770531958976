import React, {ReactNode} from 'react';
import MenuSCI from "../../lib/Menu/MenuSCI";
import {StyledHeader, StyledLayout, StyledContent} from "./styled";
interface LayoutProps {
    children: ReactNode; // тип ReactNode позволяет передавать любые дочерние компоненты
}
const LayoutSci: React.FC<LayoutProps> = ({ children }) => {
    return (
        <StyledLayout>
            <StyledHeader>
                <MenuSCI/>
            </StyledHeader>
            <StyledContent>
                {children}
            </StyledContent>
        </StyledLayout>
    );
};

export default LayoutSci;