import styled from 'styled-components'
import { Layout } from 'antd';

const { Header, Content } = Layout;
export const StyledLayout = styled(Layout)`
    background-color: white;
`

export const StyledHeader = styled(Header)`
background-color: white;
`

export const StyledContent = styled(Content) `
    width: 90%;
    margin-left: 5%;
    margin-top: 50px;
`
