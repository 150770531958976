import React, {useEffect, useState} from 'react';
import {StyledMenu} from "./styled";
import type { MenuProps } from 'antd';
import {useLocation, useNavigate} from "react-router-dom";
import PATH_NAMES from "../../../constants/pathNames";

const items: MenuProps['items'] = [
    {
        label: 'Бронирования',
        key: PATH_NAMES.BOOKINGS
    },
    {
        label: 'Гости',
        key: PATH_NAMES.GUESTS
    },
    {
        label: 'Номера',
        key: PATH_NAMES.ROOMS
    },
    {
        label: 'Типы номеров',
        key: PATH_NAMES.ROOM_TYPES
    }
]


const MenuSCI = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [current, setCurrent] = useState('');

    useEffect(() => {
        const currentUrl = location.pathname;
        if(items.find(item => item?.key === currentUrl)) {
            setCurrent(currentUrl)
        }
        else {
            setCurrent('')
        }
    }, [location] );

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(`${e.key}`)
    };
    
    return (
        <StyledMenu onClick={onClick} selectedKeys={[current]} items={items} mode="horizontal"/>
    );
};

export default MenuSCI;