import React, { Suspense } from 'react';
import LayoutSCI from "./components/blocks/Layout/LayoutSCI";
import AppRoutes from "./appRoutes";
import {ConfigProvider} from "antd";
import ruRU from 'antd/locale/ru_RU'

function App() {
    return (
            <LayoutSCI>
                <Suspense fallback={<div>Loading...</div>}>
                    <ConfigProvider locale={ruRU}>
                        <AppRoutes/>
                    </ConfigProvider>
                </Suspense>
            </LayoutSCI>
    );
}

export default App;
